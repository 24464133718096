import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen17 } from '../../../components/images/support/getting-started/Screen17.generated';
import { Screen18 } from '../../../components/images/support/getting-started/Screen18.generated';
import { Screen19 } from '../../../components/images/support/getting-started/Screen19.generated';
import { Screen20 } from '../../../components/images/support/getting-started/Screen20.generated';
import { Screen21 } from '../../../components/images/support/getting-started/Screen21.generated';
import { Screen23 } from '../../../components/images/support/getting-started/Screen23.generated';
import { Screen24 } from '../../../components/images/support/getting-started/Screen24.generated';
import { Screen25 } from '../../../components/images/support/getting-started/Screen25.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invite-an-employee-"
    }}>{`Invite an Employee 🧑‍🍳`}</h1>
    <p>{`The final step is inviting an employee.`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Users`}</strong>{` menu.`}</li>
    </ol>

    <Screen17 mdxType="Screen17" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add user`}</strong>{`.`}</li>
    </ol>

    <Screen18 mdxType="Screen18" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Now input the user information of someone you want to invite. Then click the `}<strong parentName="li">{`Invite`}</strong>{` button.
This will send and email to the employee asking them to join Canyou. When the employee accepts the invite they will
be prompted to complete any Workflows they have been assigned.`}</li>
    </ol>

    <Screen19 mdxType="Screen19" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Now we can see the employee has been invited.`}</li>
    </ol>

    <Screen20 mdxType="Screen20" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`When the employee accepts the invite the screen will show the amount of progress of the employee.`}</li>
    </ol>

    <Screen21 mdxType="Screen21" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`When the employee has completed the Workflow we can see they have a green check mark on their row.
We can click on this row to see the details of the submitted tasks.`}</li>
    </ol>

    <Screen23 mdxType="Screen23" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`We can see that the payroll flow is completed. Click on the payroll flow to see the details.`}</li>
    </ol>

    <Screen24 mdxType="Screen24" />
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`This shows us all the tasks. We can navigate through the details by clicking on the corresponding task.`}</li>
    </ol>

    <Screen25 mdxType="Screen25" />
    <h3 {...{
      "id": "great-work-you-have-finished-the-getting-started-guide-"
    }}>{`Great work you have finished the getting started guide 🥳`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      